import { RESERVATION_DETAIL } from "../routes/Paths";

export const timerConstantValue = "100";
export const thankyouPageTimerValue = "60";

//#region 
export const STEP_MASTER_ENUM = {
 
    //CHECKIN_PAGE:"CH01",
    //CHECKOUT_PAGE: "CH02",
    //KEY_PAGE: "KY01",
    //DOCUMENT_SCAN_PAGE: "DS01",
    //CONFIRMATION_NUMBER_SEARCH_PAGE: "CS01",
    //QR_CODE_PAGE: "QR01",
    HOME_PAGE: "SKI01",
    RESERVATION_SEARCH_PAGE: "SKI02",
    SCAN_DOCUMENT_PAGE: "SKI03",
    FACEVERIFICATION_PAGE: "SKI04",
    MANUAL_AUTHENTICATION_PAGE: "SKI05",
    UPSELL_PAGE: "SKI06",
    REGISTRATION_CARD_PAGE: "SKI07",
    PAYMENT_PAGE: "SKI08",
    KEY_PAGE: "SKI09",
    CHECKIN_THANKYOU_PAGE:"SKI10",
    
    FOLIO_PAGE: "SKO02",
    EMAIL_PAGE: "SKO03",
    CHECKOUT_THANKYOU_PAGE:"SKO04",
    CHECKOUT_RESERVATION_SEARCH_PAGE:"SKO01",
    CHECKOUT_PAYMENT_PAGE:"SKO05",

    KEY_SEARCH: "SKK01",
    KEY_ENCODE_PAGE: "SKK02",
    KEY_FACEVERIFICATION_PAGE_PAGE: "SKK03",
    KEY_THANKYOU_PAGE: "SKK04",
  } 

  export const MODULE_MASTER_ENUM = {
    CHECKIN:"CH01",
    CHECKOUT: "CH02",
    KEY: "KY01",
    DOCUMENT_SCAN: "DS01",
    CONFIRMATION_NUMBER_SEARCH: "CS01",
    QR_CODE: "QR01",
  } 


  export const CONFIG_MASTER_ENUM = {
    FOLIO_VIEW: "SSF",
    SIGNATURE_FOLIO_REQUIRED:"SFR",
    EMAIL_REQUIRED:"ESR",
    ADULTCOUNT_CHNAGE_REQUIRED:"ACO",
    RESERVATION_INFO:"RSF",
    SETTLE_BY_PMS:"SPMS",
    SMS_REQUIRED:"SSR",
    WHATSAPP_REQUIRED:"WSR",
    TOGGLE_PAY:"PGL",
    LANGUAGE: "LSR",
    QUEUEROOM: "QRM",
    FACE_MATCH:"FCM"
  } 
  export const STEP_CONFIG_ENUM = {
    CONFIRMATION_CHKDT: "CCD",
    LASTNAME_CHKDT: "LCD",
    LASTNAME_CONFIRMATION: "LCN",
    LASTNAME_ROOMNUMBER: "LRN",
    QR_CODE: "QR",
    LASTNAME_ROOMNUMBER_DATE: "LRND",
    
    
  } 

  export const SESSION_ENUM = {
    PRECHECKIN: "preCheckin",
  } 
//#endregion