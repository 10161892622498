/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import "../../assets/stylesheet/upgrade-option.css";

import { STEP_MASTER_ENUM, timerConstantValue } from "../../constants/constant";
import {
  getParametersFromLocalStorage,
  homePageHandler,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import DoneModal from "..//Layout/Card/DoneModal";
import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import Header from "../Layout/Header";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import { RESERVATION_DETAIL } from "../../routes/Paths";
import SkipModal from "../Layout/Card/SkipModal";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import UpgradeConfirmationCard from "../Layout/Card/UpgradeConfirmationCard";
import UpgradeRoom from "./UpgradeRoom";
import {
  addPackage,
} from "../../network/apiService";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPassportScanData } from "../../store/Checkin/Scan/scanPassportSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getconfirmationnumberData } from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Upgrade = ({
  skipHandler,
  upgradeRoomModal,
  toggleSkipModal,
  toggleDoneModal,
  // timerValue,
  // restartTimer,
  upgrade,
  roomOptions,
  // openModal,
  skipModal,
  resetStates,
  removeRoomOptions,
  openUpgradeModal,
  closeUpgradeModal,
  closeSkipModal,
  closeDoneModal,
  // upgradeHandler,
  setUpgrade,
  setRoomOptions,
  navigateUrl,
  upgradeOptions
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [upgrades, setUpgradeOptions] = useState([]);
  ///const [upgradeOptions, setUpgradeOptions] = useState(null);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [proceedEnabled, setProceedEnabled] = useState(false);
  const token = getParametersFromLocalStorage("token");
  const confirmationNo = localStorage.getItem("confirmationNo");
  const reservationNameID = localStorage.getItem("reservationNameID");
  const userData = useSelector(getPassportScanData);
  const propertyData = useSelector(getPropertyInfo);
  const reservationData = useSelector(getconfirmationnumberData);
  const totalCount = userData?.adultCount || 1;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const upgradePage =
    (stepMaster && stepMaster.length
      ? stepMaster.find((ele) => ele.stepCode === STEP_MASTER_ENUM.UPSELL_PAGE)
        ?.isEnabled
        ? true
        : false
      : false) ?? false;

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);    
  }, []);

  useEffect(async () => {    
    setUpgradeOptions(upgradeOptions);
  }, [upgradeOptions]);

  

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          t("Unable to proceed with your request. Please contact front desk for assistance.")
        ),
      }));
    }
  };
  // const generateToken = async () => {
  //   try {
  //     const response = await generateTokenApi({
  //       userName: JSON.parse(localStorage.getItem("username")),
  //       password: JSON.parse(localStorage.getItem("password")),
  //     });
  //     if (response.status === 200) {
  //       const data = response.data || {};
  //       const propertyResponse = await getPropertyData(token);
  //       if (propertyResponse?.status === 200) {
  //         fetchUpgradeOptions(token);
  //         return true;
  //       }
  //       return false;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     return false;
  //   }
  //   return false;
  // };



  const wait = (milliseconds) =>
    new Promise((res, rej) =>
      setTimeout(
        () =>
          rej(
            new Error(`Request timed out after ${milliseconds / 1000} seconds`)
          ),
        milliseconds
      )
    );

 
  // useEffect(() => {
  //   const anyOptionSelected = upgradeOptions.some((option) => option.selected);
  //   setProceedEnabled(true);
  // }, [upgradeOptions]);

  const upgradeHandler = async (option) => {
    setShowConfirmationModal(true);
    setSelectedPackage(option);
  };

  const proceedWithPackage = async (e) => {
    setShowConfirmationModal(false);
    setLoading(true);
    e.preventDefault();
    if (selectedPackage) {
      const packageData = {
        addOnItem: {
          itemCode: selectedPackage.packageCode,
          quantity: totalCount,
          itemType: selectedPackage.packageType,
          startDate: reservationData?.arrivalDate,
          endDate: reservationData?.departureDate,
        },
        confirmationNumber: confirmationNo,
        reservationNameID: reservationNameID || "",
      };
      try {
        const response = await Promise.race([
          addPackage(packageData, token),
          wait(60000),
        ]);
        if (response.data.data.statusCode === 200) {
          const upgradeOption = upgrades.map((option) =>
            option.packageTitle === selectedPackage.packageTitle
              ? { ...option, selected: true }
              : option
          );
          setUpgradeOptions(upgradeOption);
          setLoading(false);
          setSuccessLoader(true);
          setTimeout(() => {
            setSuccessLoader(false);
          }, 2000);
        }
      } catch (error) {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            t("Unable to add package. Please contact front desk for assistance.")
          ),
        }));
      }
    }
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const proceed = async () => {
    const anyOptionSelected = upgrades.some((option) => option.selected);
    if (anyOptionSelected === null || anyOptionSelected === undefined || anyOptionSelected === false) {
      skipModal();
    } else {
      navigate(`${RESERVATION_DETAIL}`, { replace: true });
    }
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const closeConfirmationModalPopup = () => {
    setShowConfirmationModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  return (
    <div>
      <main>
        <div className="main-content">
          <div className="mt-30">
            <Header
              onClick={handlebackPage}
              setShowModal={setShowModal}
              isHome={true}
              title={t("Please select an upgrade option or skip")}
              subTitle={""}
            />
          </div>
          <div className="upgrade-section">
            {upgrades?.map((option, index) => (
              <div
                key={index}
                className="upgrade-box"
                style={{
                  border: option.selected
                    ? "4px solid orange"
                    : "2px solid black",
                }}
              >
                <div className="upgrade-image">
                  {/* {option.packageTitle === "Breakfast" && (
                    <img src={BreakFast} alt="Breakfast" />
                  )}
                  {option.packageTitle === "Late Check-out" && (
                    <img src={EarlyCheckOut} alt="Late Check-out" />
                  )}
                  {option.packageTitle === "Parking" && (
                    <img src={Parking} alt="Parking" />
                  )} */}
                  <img src={option?.packageBg} alt="img" />
                </div>
                <div className="upgrade-caption">
                  <p>{option.packageTitle}</p>
                  <p>{option.description}</p>
                  <span>
                    {propertyData?.data?.currency}{" "}
                    {parseFloat(option.amount ?? 0).toFixed(2)}

                  </span>
                  <button
                    disabled={option.selected}
                    onClick={() => upgradeHandler(option)}
                    style={{
                      backgroundColor: option.selected ? "white" : "#0664A7",
                      color: option.selected ? "black" : "white",
                      border: option.selected ? "2px solid black" : "none",
                    }}
                  >
                    {option.selected ? t("Selected") : t("Select")}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="buttons-container">
            <div style={{ display: "none" }}>
              <button
                className="btn skip-btn"
                data-bs-toggle="modal"
                data-bs-target="#skip-modal"
                onClick={() => {
                  skipModal();
                }}
              >
                {t("Skip")}
                <i className="bx bx-last-page"></i>
              </button>
            </div>
            <div>
              <button
                className="btn skip-btn"
                // disabled={!proceedEnabled}
                onClick={() => {
                  proceed();
                }}
                style={{
                  backgroundColor: proceedEnabled ? "#F67F45" : "white",
                }}
              >
                {t("Proceed")}
              </button>
            </div>
          </div>
        </div>
      </main>
      {/* <LoaderPopup loading={loading} /> */}

      {upgradeRoomModal && (
        <UpgradeRoom
          closeModal={closeUpgradeModal}
          roomOptions={roomOptions}
          setRoomOptions={setRoomOptions}
        />
      )}
      {toggleSkipModal && (
        <SkipModal
          closeModal={closeSkipModal}
          skipHandler={skipHandler}
          navigateUrl={navigateUrl}
        />
      )}
      {toggleDoneModal && (
        <DoneModal
          closeModal={closeDoneModal}
          setUpgrade={setUpgrade}
          setRoomOptions={setRoomOptions}
          navigateUrl={navigateUrl}
        />
      )}
      {showConfirmationModal && (
        <UpgradeConfirmationCard
          showModal={showConfirmationModal}
          closeModal={() => setShowConfirmationModal(false)}
          onConfirm={proceedWithPackage}
          resetTimerAndClosePopUp={closeConfirmationModalPopup}
        />
      )}
      <SuccessPopup
        loading={successLoader}
        successMessage={t("Package added successfully!")}
      />
      <ErrorPopup
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
      />
    </div>
  );
};

export default Upgrade;
