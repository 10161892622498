import {
  resetGuestCount,
  userReservationDetailsConfirmatioNo,
} from "../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import {
  resetProfileList,
  resetRetryFaceCount,
  resetScanDocument,
  resetScanDocumentGuestCount,
} from "../store/Checkin/Scan/documentScanSlice";

import { HOME } from "../routes/Paths";
import { SESSION_ENUM } from "../constants/constant";
import { generateTokenApi } from "../network/apiService";
import i18next from "i18next";
import moment from "moment";
import { resetFaceData } from "../store/Checkin/faceVerificationSlice";
import { resetReservations } from "../store/Checkin/reservationformSlice";
import { useTranslation } from "react-i18next";
import { userReservationDetailsCheckoutPage } from "../store/Checkout/checkoutSlice";
import { userReservationDetailsPassport } from "../store/Checkin/Scan/scanPassportSlice";
import { userReservationDetailsQr } from "../store/Checkin/QrCode/qrCodeSlice";

export const saveParametersIntoLocalStorage = (dataKey, data) => {
  localStorage.setItem(dataKey, JSON.stringify(data));
};

export const saveIntoLocalStorage = (dataKey, data) => {
  localStorage.setItem(dataKey, data);
};

export const removeParametersFromLocalStorage = (datakey, data) => {
  localStorage.removeItem(datakey);
};

export const generateNewSessionToken = async () => {
  const newToken = await reGenerateToken();
  if (newToken !== undefined) {
    saveTokenInSessionStorage(newToken);
  }
  return newToken;
};

export const getParametersFromLocalStorage = (datakey, data) => {
  if (datakey === "token") {
    return getParametersFromSessionStorage(datakey);
  } else {
    let res = localStorage.getItem(datakey);
    if (res) {
      return JSON.parse(res);
    }
    return null;
  }
};

export const saveTokenInSessionStorage = (token) => {
  const tokenData = JSON.parse(atob(token.split(".")[1]));
  const expiryTime = tokenData.exp * 1000;
  const expiryTimestamp = new Date(expiryTime).toISOString();
  sessionStorage.setItem("token", JSON.stringify(token));
  sessionStorage.setItem("tokenExpiry", expiryTimestamp);
  // const expiryDate = "2023-12-31T23:59:59Z";
  // sessionStorage.setItem("tokenExpiry", expiryDate);
};
export const isSessionTokenExpired = () => {
  const expiryTimestamp = sessionStorage.getItem("tokenExpiry");
  if (!expiryTimestamp) {
    return true;
  }
  const expiryTime = new Date(expiryTimestamp).getTime();
  return Date.now() > expiryTime; //returns true if session token is expired
};

export const getParametersFromSessionStorage = (datakey) => {
  let res = sessionStorage.getItem(datakey);
  if (datakey === "token") {
    const expiryTimestamp = sessionStorage?.getItem("tokenExpiry");
    if (!expiryTimestamp) {
      //sessionStorage.removeItem("token");
      return null;
    }
    const expiryTime = new Date(expiryTimestamp).getTime();
    if (Date.now() > expiryTime) {
      //sessionStorage.removeItem("token");
      return null;
    }
  }
  if (res) {
    return JSON.parse(res);
  }
  return null;
};
const pad = (number) => {
  return number < 10 ? "0" + number : number;
};
export const formatSysDate = (date) => {
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const year = date.getFullYear();
  return year + "-" + month + "-" + day + "T00:00:00";
};
export const formatDate = (date) => {
  if (!date) return null;
  const settings = getParametersFromLocalStorage("settings");
  const dateFormatParam = settings?.find(
    (setting) => setting.parameterName === "DateFormat"
  );
  const dateFormat = dateFormatParam?.parameterValue;
  let options = { day: "2-digit", month: "2-digit", year: "numeric" };
  if (dateFormat?.includes("mmm")) {
    options = { day: "2-digit", month: "short", year: "numeric" };
  }

  const formattedDate = new Date(date)?.toLocaleDateString("en-US", options);
  // Split the formatted date into day, month, and year parts
  // const [month, day, year] = formattedDate.split(" ");

  // // Convert the month abbreviation to uppercase
  // const capitalizedMonth = month.toUpperCase();
  // // Return the formatted date with uppercase month abbreviation and desired format

  // switch (dateFormat) {
  //   case "dd/mm/yyyy":
  //     return `${day?.toString().replace(/,/g, "")}/${month}/${year}`;
  //   case "dd/mmm/yyyy":
  //       return `${day?.toString().replace(/,/g, "")}/${capitalizedMonth}/${year}`;
  //   case "mm/dd/yyyy":
  //       return `${month?.toString().replace(/,/g, "")}/${day}/${year}`;
  //   case "mmm/dd/yyyy":
  //     return `${capitalizedMonth}/${day?.toString().replace(/,/g, "")}/${year}`;
  //   default:
  //     return `${day?.toString().replace(/,/g, "")}/${month}/${year}`;
  // }
  return formattedDate;
};

export const convertToDate = (string) => {
  //  console.log(format(new Date(string), 'mm/dd/yyyy'))
  let day = string.substring(0, 2);
  let month = string.substring(3, 4);
  let year = string.substring(6, 10);
  var options = { day: "dd", month: "mm", year: "yyyy" };

  return new Date(day, month, year).toLocaleDateString("en-US", options);
};

export const capitalizeFullName = (fullName) => {
  // Split the fullName into words using spaces as delimiters
  let words = fullName?.split(" ");

  if (words === null || words === undefined || words === "") {
    return "";
  }

  // Capitalize each word and handle parts with periods
  const capitalizedWords = words?.map((word) => {
    // Split the word by periods if they exist, then capitalize each part
    let subWords = word?.split(".").map((subWord) => {
      return subWord.length > 0
        ? subWord[0].toUpperCase() + subWord.slice(1).toLowerCase()
        : subWord;
    });

    // Join the parts back with periods
    return subWords.join(".");
  });

  // Join the capitalized words back into a single string
  const capitalizedFullName = capitalizedWords.join(" ");

  return capitalizedFullName;
};

export const nameValidation = (firstName, lastName) => {
  let sortedName = "";
  if (firstName && lastName) {
    sortedName = capitalizeFullName(firstName + " " + lastName);
  } else if (!firstName && lastName) {
    sortedName = "" + capitalizeFullName(" " + lastName);
  } else if (firstName && !lastName) {
    sortedName = capitalizeFullName(firstName);
  } else {
    sortedName = "";
  }
  return sortedName;
};
export const currentDate = () => {
  let currentDateTime = new Date();
  return currentDateTime;
};

export const verifyScanData = (
  scanData,
  profile,
  documentType,
  profileList,
  isChild,
  adultAgeLimit,
  childAgeLimit,
  isBackSide = false,
  fullName,
  documentListTypes
) => {
  let scanResult = true;
  let flipDocument = false;
  let error = "";
  const currentDate = new Date();
 debugger
  if (scanData.ExpiryDate !== "") {
    const expDate = moment(scanData.ExpiryDate, "DD/MM/YYYY").toDate();

    if (expDate < currentDate) {
      flipDocument = false;
      scanResult = false;
      error = i18next.t(
        "Document expired. Please provide a valid and up-to-date document for verification."
      );
      return [flipDocument, scanResult, error];
    }
  }

  if (
    isChild === false &&
    adultAgeLimit > 0 &&
    scanData.DateOfBirth !== undefined &&
    scanData.DateOfBirth !== null &&
    scanData.DateOfBirth !== ""
  ) {
    const dob = new Date(scanData.DateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    if (
      today.getMonth() < dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    const isAgeLess = age < adultAgeLimit;

    if (isAgeLess) {
      //error = "The person is under " + adultAgeLimit + " years old.";
      error = "The person is under the age of " + adultAgeLimit;
      return [flipDocument, scanResult, error];
    }
  }
  if (
    isChild === true &&
    childAgeLimit > 0 &&
    scanData.DateOfBirth !== undefined &&
    scanData.DateOfBirth !== null &&
    scanData.DateOfBirth !== ""
  ) {
    const dob = new Date(scanData?.DateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    if (
      today.getMonth() < dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    const isUnder18 = age > childAgeLimit;

    if (isUnder18) {
      error = "Ensure child age is under years " + childAgeLimit;
      return [flipDocument, scanResult, error];
    }
  }

  if (
    isChild === false &&
    adultAgeLimit > 0 &&
    scanData.DateOfBirth !== undefined &&
    scanData.DateOfBirth !== null &&
    scanData.DateOfBirth !== ""
  ) {
    const dob = new Date(scanData.DateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    if (
      today.getMonth() < dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    const isAgeLess = age < adultAgeLimit;

    if (isAgeLess) {
      error = "The person is under " + adultAgeLimit + " years old.";
      return [flipDocument, scanResult, error];
    }
  }
  if (
    isChild === true &&
    childAgeLimit > 0 &&
    scanData.DateOfBirth !== undefined &&
    scanData.DateOfBirth !== null &&
    scanData.DateOfBirth !== ""
  ) {
    const dob = new Date(scanData?.DateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    if (
      today.getMonth() < dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    const isUnder18 = age > childAgeLimit;

    if (isUnder18) {
      error = "Ensure child age is under years " + childAgeLimit;
      return [flipDocument, scanResult, error];
    }
  }

  if (
    profileList !== null &&
    profileList !== undefined &&
    profileList?.length > 0 &&
    scanData?.DocumentNumber !== ""
  ) {
    let isExist = profileList?.filter(
      (p) => p.DocumentNumber === scanData?.DocumentNumber
    );
    if (isExist?.length > 0) {
      flipDocument = false;
      scanResult = false;
      error = i18next.t("Document already scanned.");
      return [flipDocument, scanResult, error];
    }
  }
  if (
    scanData.DocumentType !== "" &&
    scanData.DocumentType !== undefined &&
    scanData.Portrait !== undefined &&
    scanData.Portrait !== null &&
    scanData.Portrait !== ""
  ) {
    let verifyDocType = false;
    documentType.forEach((el) => {
      if (el === scanData.DocumentType) {
        verifyDocType = true;
      }
    });
    if (!verifyDocType) {
      flipDocument = false;
      scanResult = false;
      error = i18next.t(
        "Invalid document type. Please use a valid document such as a passport or ID card for verification."
      );
      return [flipDocument, scanResult, error];
    }
  }
else if( scanData.DocumentType === "" || scanData.DocumentType === undefined)
{
  flipDocument = false;
  scanResult = false;
  error = i18next.t(
    "Invalid document type. Please use a valid document such as a passport or ID card for verification."
  );
  return [flipDocument, scanResult, error];
}


  // if (
  //   scanData !== null &&
  //   //(scanData.DocumentType === "P" || scanData.DocumentType === "ID" || scanData.DocumentType === "IL") &&
  //   scanData.LastName !== ""
  // ) {
  //   if (profile !== null && profile !== undefined) {
  //     if (
  //       profile.lastName?.toUpperCase() === scanData.LastName?.toUpperCase()
  //     ) {
  //       if (scanData.Age < 18 && profile.isPrimary === true) {
  //         flipDocument = false;
  //         scanResult = false;
  //         error = "Primary guest should be an adult";
  //         return [flipDocument, scanResult, error];
  //       } else {
  //         flipDocument = false;
  //         scanResult = true;
  //         return [flipDocument, scanResult, error];
  //       }
  //     } else {
  //       flipDocument = false;
  //       scanResult = false;
  //       error = "Lastname does not match. Please provide the valid Lastname";
  //       return [flipDocument, scanResult, error];
  //     }
  //   } else {
  //     flipDocument = false;
  //     scanResult = false;
  //     error =
  //     "Please scan backside";
  //     return [flipDocument, scanResult, error];
  //   }
  // }

  if (scanData === null) {
    flipDocument = false;
    scanResult = false;

    error = i18next.t(
      "No document detected. Please place a valid document on the scanner for verification."
    );
    return [flipDocument, scanResult, error];
  }
  if (isBackSide === false) {
    documentListTypes.forEach((el) => {
      if (
        el?.BODocumentType === scanData.DocumentType &&
        el?.IsBackSide === true
      ) {
        flipDocument = true;
      }
    });
    if (flipDocument) {
      return [flipDocument, scanResult, error];
    }
  }
  // if (
  //   scanData.DocumentType !== "P" &&
  //   scanData.DocumentType !== "DL" &&
  //   isBackSide === false
  // ) {
  //   flipDocument = true;
  //   return [flipDocument, scanResult, error];
  // }
  if (profile !== null && profile !== undefined) {
    if (
      scanData.Age !== undefined &&
      scanData.Age !== null &&
      scanData.Age !== "" &&
      parseInt(scanData.Age) < 18 &&
      profile.isPrimary === true
    ) {
      flipDocument = false;
      scanResult = false;
      error = "Primary guest should be an adult";
      return [flipDocument, scanResult, error];
    }

    const docFullName = fullName;
    // let operaFullName2 = profile?.firstName ? profile?.firstName : ' ' + ' ' + profile?.middleName ? profile?.middleName : ' ' + ' ' +
    //   profile?.lastName ? profile?.lastName : ' ';

    const operaFullName =
      (profile?.firstName === undefined || profile?.firstName === null
        ? ""
        : profile?.firstName) +
      " " +
      (profile?.middleName === undefined || profile?.middleName === null
        ? ""
        : profile?.middleName) +
      " " +
      (profile?.lastName === undefined || profile?.lastName === null
        ? ""
        : profile?.lastName);
    const docNames = removeSpecialCharacters(docFullName.toLowerCase()).split(" ");
    const operaNames = removeSpecialCharacters(operaFullName.toLowerCase()).split(" ");

    if (docFullName?.replace(" ", "")?.length <= 0) {
      flipDocument = false;
      scanResult = false;
      error = i18next.t("Name not matching with reservation name");
      return [flipDocument, scanResult, error];
    }

    const settings = getParametersFromLocalStorage("settings");
    const nameMatchparam = settings?.find(
      (setting) => setting.parameterName === "NameValidation"
    );
    const nameMAxMatch = parseInt(nameMatchparam?.parameterValue ?? 1);

    let matchingCount = 0;

    docNames?.forEach(docName => {   
     
      operaNames?.forEach(operaName => {
        if(docName !== "" && operaName !== "")   {
          if (operaName?.toLowerCase() === docName?.toLowerCase()) {
            matchingCount += 1;
          }
        }        
      });
    });
    
    if (matchingCount < nameMAxMatch) {
      flipDocument = false;
      scanResult = false;
      error = i18next.t("Name not matching with reservation name");
      return [flipDocument, scanResult, error];
    }
  }
  return [flipDocument, scanResult, error];
};
function removeSpecialCharacters(name) {
  return name?.replace(/[^a-zA-Z0-9 ]/g, '');
}
export const formatCardExpiry = (cardExpiry) => {
  // Assuming cardExpiry is in the format "yymm"
  if (cardExpiry && cardExpiry.length === 4) {
    const year = parseInt(cardExpiry.substring(2, 4)) + 2000;
    const month = cardExpiry.substring(0, 2);
    const formattedExpiry = `${year}-${month}-01`; // Assuming day as 01, you can change it as needed
    return formattedExpiry;
  }
  // Handle invalid or missing cardExpiry here
  return null;
};
export const updateTimeToDefaultCheckout = (datetime, defaultTime) => {
  const date = new Date(datetime);
  date.setHours(defaultTime, 0, 0); // Set time to 12:00 AM (00:00:00 in 24-hour format)
  return date.toISOString().split(".")[0];
};

export const homePageHandler = (dispatch, navigate) => {
  localStorage.removeItem("confirmationNo");
  localStorage.removeItem("LastName");
  localStorage.removeItem("reservationNameID");
  localStorage.removeItem("DueAmount");
  localStorage.removeItem("totalCount");
  localStorage.removeItem("ScanSkippedinKiosk");
  localStorage.removeItem("RoomPin");
  localStorage.removeItem("balanceAmount");
  localStorage.removeItem("faceMatch");
  localStorage.removeItem("totalCount");
  localStorage.removeItem(SESSION_ENUM.PRECHECKIN);

  dispatch(userReservationDetailsCheckoutPage({}));
  dispatch(userReservationDetailsQr({}));
  dispatch(userReservationDetailsPassport({}));
  dispatch(userReservationDetailsConfirmatioNo({}));
  dispatch(resetProfileList());
  dispatch(resetReservations());
  dispatch(resetFaceData());
  dispatch(resetScanDocumentGuestCount());
  dispatch(resetScanDocument());
  dispatch(resetGuestCount());
  dispatch(resetRetryFaceCount());

  if (navigate !== null && navigate !== undefined)
    navigate(`${HOME}`, { replace: true });
  // history.replace(HOME);
  return;
};

export const getStepMaster = (stepMaster, stepCode) => {
  return (
    (stepMaster && stepMaster.length
      ? stepMaster.find((ele) => ele.stepCode === stepCode)
      : null) ?? null
  );
};

export const getModuleStatus = (modules, moduleCode) => {
  return (
    (modules && modules.length
      ? modules.find((ele) => ele.moduleCode === moduleCode)?.isEnabled
        ? true
        : false
      : false) ?? false
  );
};
export const reGenerateToken = async () => {
  var param = {
    userName: JSON.parse(localStorage.getItem("username")),
    password: JSON.parse(localStorage.getItem("password")),
  };
  if (param?.userName !== null || param?.password !== null) {
    const response = await generateTokenApi({
      userName: param.userName,
      password: param.password,
    });

    if (response.status === 200) {
      const data = response.data || {};
      return data?.token;
    }
  }
};

export const getConfigurationCode = (modules, ConfigurationCode) => {
  return (
    (modules && modules.length
      ? modules.find((ele) => ele.stepConfigurationCode === ConfigurationCode)
          ?.isEnabled
        ? true
        : false
      : false) ?? false
  );
};
export const getConfigurationCodeMandatory = (modules, ConfigurationCode) => {
  return (
    (modules &&
      modules.length &&
      modules.find((ele) => ele.stepConfigurationCode === ConfigurationCode)
        ?.isEnabled &&
      modules.find((ele) => ele.stepConfigurationCode === ConfigurationCode)
        ?.isMandatory) ??
    false
  );
};
export const addPrefixIfSingleLetter = (str) => {
  if (str.length === 1 && str !== "0") {
    return `0${str}`;
  }
  return str;
};

export const getScanDataFullName = (scanDataFront, scanDataBack) => {
  const getFullName = (data) => {
    const firstName = data?.FirstName || "";
    const middleName = data?.MiddleName || "";
    const lastName = data?.LastName || ""; // Fixing case sensitivity
    let name = `${firstName} ${middleName} ${lastName}`.trim();

    if (name?.split(" ").length < data?.FullName?.split(" ").length) {
      name = data?.FullName;
    }
    if (name?.split(" ").length < data?.GivenName?.split(" ").length) {
      name = data?.GivenName;
    }   
    return name;
  };
  const fullName = getFullName(scanDataFront);

  debugger
  const scanFullNameFront =
    fullName === null || fullName === undefined || fullName === ""
      ? scanDataFront?.GivenName
      : fullName;

  const fullNameBack = getFullName(scanDataBack);

  const scanFullNameBack =
    fullNameBack === null || fullNameBack === undefined || fullNameBack === ""
      ? scanDataBack?.GivenName
      : fullNameBack;

  // Return the full name based on the availability
  if (
    scanFullNameFront !== null &&
    scanFullNameFront !== undefined &&
    scanFullNameFront !== ""
  ) {
    return scanFullNameFront;
  } else {
    return scanFullNameBack;
  }
};

export const getProfileDataFullName = (profile) => {
  const getFullName = (data) => {
    if (data !== null && data !== undefined) {
      const title =
        data?.title === null || data?.title === undefined || data?.title === ""
          ? ""
          : data?.title.includes(".")
          ? data?.title
          : `${data?.title}.`;
      let names = [];
      if (
        data.GivenName?.split(" ").length > data.FullName?.split(" ").length &&
        data.GivenName !== data.FullName
      ) {
        names = data.GivenName?.split(" ");
      } else {
        names = data.FullName?.split(" ");
      }

      const firstName =
        data?.firstName ||
        (Array.isArray(names) && names[names.length - 1]) ||
        "";
      const middleName =
        data?.middleName || (Array.isArray(names) && names[1]) || "";
      const lastName =
        data?.lastName || (Array.isArray(names) && names[0]) || ""; // Fixing case sensitivity
      return `${title} ${firstName} ${middleName} ${lastName}`.trim();
    } else {
      return "";
    }
  };
  const fullName = getFullName(profile);
  const profileFullName =
    fullName === null || fullName === undefined || fullName === ""
      ? profile?.GivenName
      : fullName;
  // Return the full name based on the availability
  return profileFullName;
};

export const getProfileName = (scanDataFront, scanDataBack, profile) => {
  const scanFullName = getScanDataFullName(scanDataFront, scanDataBack);
  const profileFullName = getProfileDataFullName(profile);

  if (
    profileFullName !== null &&
    profileFullName !== undefined &&
    profileFullName !== ""
  ) {
    return capitalizeFullName(profileFullName);
  } else {
    return capitalizeFullName(scanFullName);
  }
};
