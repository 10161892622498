import {
  CHECKIN_PAYMENT,
  CHECKIN_THANKYOU,
  HOME,
  KEY,
} from "../../routes/Paths";
import {
  CONFIG_MASTER_ENUM,
  SESSION_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import {
  checkIn,
  getReservationPaymentInfo,
  queueRoom,
  saveRegistrationCard,
  updateUserProfile,
} from "../../network/apiService";
import {
  fetchCountries,
  getCountryList,
  getProfilesListData,
} from "../../store/Checkin/reservationformSlice";
import { fetchStep, getIsSkipped } from "../../store/stageSlice";
import {
  formatDate,
  getConfigurationCode,
  getParametersFromLocalStorage,
  getProfileName,
  homePageHandler,
} from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";
import {
  resetScanPassportGuestCount,
  setEmailDetails,
  userReservationDetailsPassport,
} from "../../store/Checkin/Scan/scanPassportSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import CheckoutTimeModal from "../../components/Layout/Card/CheckoutTimeModal";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import MultipleRegCard from "../../components/Layout/Card/MultipleRegCard";
import SuccessPopup from "../../components/LoaderAndNotification/SuccessPopup";
import UserReservationDetails from "../../components/ReservationDetails/UserReservationDetails";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPassportScanData } from "../../store/Checkin/Scan/scanPassportSlice";
import { getProfileData } from "../../store/Checkin/Scan/documentScanSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getStepMaster } from "../../helpers/commonHelper";
import i18n from "i18next";
import moment from "moment";
import { setMultiConfirmationNos } from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useNavigate } from "react-router-dom";

const ReservationDetailsPassportScan = () => {
  const timeLimit = timerConstantValue;
  let noOfNights = null;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [enterEmailValidation, setEnterEmailValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [countryValidation, setCountryValidation] = useState(false);
  const [mobileNoValidation, setMobileNoValidation] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [toggleCheckoutTimeModal, setToggleCheckoutTimeModal] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [togglePackageApi, setTogglePackageApi] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [totalDue, setTotalDue] = useState(null);
  const [reservationData, setReservationData] = useState();
  const [confirmationDetails, setConfirmationDetails] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const [successMessage2, setSuccessMessage2] = useState(null);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData4, setErrorModalData4] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [userAgreed, setUserAgreed] = useState(false);
  const [signature, setSignature] = useState(null);
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getParametersFromLocalStorage("token");
  const [stage, setStage] = useState(false);
  const userDataDeatils = useSelector(getPassportScanData);

  const [proceedCount, setProceedCount] = useState(0);
  const [guestCount, setGuestCount] = useState(0);

  const [userData, setUserData] = useState(userDataDeatils);

  const [closeModal, setCloseModal] = useState(false);
  const [showMultiConfirmModal, setShowMultiConfirmModal] = useState(false);
  const confirmNo = localStorage.getItem("confirmationNo");
  const reservationNameID = localStorage.getItem("reservationNameID");
  const propertyCode = localStorage.getItem("propertyCode");
  const countries = useSelector(getCountryList);
  const isSkipped = useSelector(getIsSkipped);
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const reservationDetailsPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.REGISTRATION_CARD_PAGE
  );
  const [confirmationNos, setConfirmationNos] = useState([]);
  const [isDoCheckin, setDoCheckin] = useState(false);
  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openCheckOutTimeModal = () => {
    setToggleCheckoutTimeModal(true);
  };

  const closeCheckoutTimeModal = () => {
    setToggleCheckoutTimeModal(false);
    setTimeChanged(false);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const confirmSharerReg = () => {
    setShowMultiConfirmModal(false);
    setLoading(true);
    let fullName = "";
    if (
      profileList[proceedCount] !== null &&
      profileList[proceedCount] !== undefined
    ) {
      fullName = getProfileName(null, null, profileList[proceedCount]);
    } else {
      fullName = getProfileName(null, null, profileData[guestCount]);
    }
    setTimeout(() => {
      setReservationData((prevState) => ({
        ...prevState,
        pmsConfirmationNo: profileData[guestCount]?.pmsConfirmationNo,
        pmsProfileID: profileData[guestCount]?.pmsProfileID,
        firstName: profileData[guestCount]?.FirstName,
        name: fullName, //profileData[proceedCount]?.GivenName,
        email: profileList[proceedCount]?.email ?? "",
        mobileNo: profileList[proceedCount]?.phoneNo_Res ?? "",
        lastName: profileData[guestCount]?.LastName,
        adults: 0,
        roomRate: profileData[guestCount]?.rateCodeAmount || "0.0",
        totalAmount: profileData[guestCount]?.totalAmount || "0.0",
        balanceAmount: profileData[guestCount]?.balanceAmount || "0.0",
        totalAmountDue: profileData[guestCount]?.totalAmountDue,
        // reservationNameID: profileData[proceedCount]?.reservationNameID
      }));
      setConfirmationDetails({
        email: profileList[proceedCount]?.email ?? "",
        mobileNo: profileList[proceedCount]?.phoneNo_Res ?? "",
        country: profileList[proceedCount]?.countryCode,
        pmsConfirmationNo: profileData[guestCount]?.pmsConfirmationNo,
        reservationNameID: profileData[guestCount]?.reservationNameID,
        firstName: profileData[guestCount]?.FirstName,
        lastName: profileData[guestCount]?.LastName,
        name: fullName,
        //profileData[proceedCount]?.GivenName,
      });
      clearSign();
      setLoading(false);
    }, 2000);

    //api call to save reg card & checkin
  };
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(() => {
    setDoCheckin(false);

    if (reservationDetailsPage === false) {
      navigate(`${CHECKIN_PAYMENT}`, { replace: true });
      return;
    }
    if (
      (countries === null || countries === undefined) &&
      token !== null &&
      token !== undefined
    ) {
      dispatch(fetchCountries(token));
    }
  }, []);
  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);
  useEffect(() => {
    if (
      confirmNo != null &&
      confirmNo !== undefined &&
      propertyCode !== null &&
      propertyCode !== undefined
    ) {
      dispatch(
        fetchStep({
          confirmationNo: confirmNo,
          propertyCode: propertyCode,
          type: "checkin",
          token: token,
          stepCode: "SEI04",
          profile: null,
        })
      )
        .unwrap()
        .then((response) => {
          console.log("isSkipRegCard==>" + response);
          dispatch(resetScanPassportGuestCount());
          fetchReservationDetails(
            {
              ConfirmationNo: confirmNo,
              PropertyCode: propertyCode,
              ReservationNameID: reservationNameID,
            },
            (response)
          );
        });
    }
  }, [confirmNo, propertyCode]);

  const findNumberOfNights = (arrivalDate, departureDate) => {
    let noOfNights = null;
    if (arrivalDate && departureDate) {
      const date1 = new Date(moment(arrivalDate).format("YYYY-MM-DD"));
      const date2 = new Date(moment(departureDate).format("YYYY-MM-DD"));
      const timeDiff = date2.getTime() - date1.getTime();
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      noOfNights = nights;
    }
    return noOfNights;
  };

  const profileData = useSelector(getProfileData);
  const propertyData = useSelector(getPropertyInfo);
  const profileList = useSelector(getProfilesListData);

  const fetchReservationDetails = async (query, isSkipRegCard) => {
    setLoading(true);
    try {
      // checkStep();
      const response = await getReservationPaymentInfo(query, token);

      //const response = await getReservationDetails(query, token);
      if (response?.data?.result === true && response.data.data != null) {
        dispatch(userReservationDetailsPassport(response?.data?.data));

        noOfNights = findNumberOfNights(
          response?.data?.data?.arrivalDate,
          response?.data?.data?.departureDate
        );
        const amount = parseFloat(response.data?.data?.totalAmountDue ?? 0);       
        console.log("isSkipRegCard===>" + isSkipRegCard);
        
        if (isSkipRegCard === true) {
          if (amount > 0) {
            navigate(`${CHECKIN_PAYMENT}`, { replace: true });
            setLoading(false);
          } else {
            setDoCheckin(true);
            setLoading(false);            
          }
          return;
        }

        setReservationData({
          firstName: response?.data?.data?.firstName,
          lastName: response?.data?.data?.lastName,
          name: getProfileName(null, null, response?.data?.data),
          roomNo: response?.data?.data?.roomNo || "",
          // arrivalDate:
          //   moment(response?.data?.data?.arrivalDate).format("YYYY-MM-DD") ||
          //   "",
          // departureDate:
          //   moment(response?.data?.data?.departureDate).format("YYYY-MM-DD") ||
          //   "",
          arrivalDate: formatDate(response?.data?.data?.arrivalDate),
          departureDate: formatDate(response?.data?.data?.departureDate),
          nights: noOfNights,
          adults: response?.data?.data?.adultCount,
          child: response?.data?.data?.childCount,
          checkoutTime:
            moment(response?.data?.data?.departureDate).format("hh:mm A") || "",
          roomType: response?.data?.data?.roomTypeName || "",
          roomRate: response?.data?.data?.rateCodeAmount || "0.0",
          totalAmount: response?.data?.data?.totalAmount || "0.0",
          balanceAmount: response?.data?.data?.balanceAmount || "0.0",
          pmsProfileID: response?.data?.data?.pmsProfileID,
          pmsConfirmationNo: response?.data?.data?.pmsConfirmationNo,
          totalAmountDue: response.data?.data?.totalAmountDue,
        });
        setConfirmationDetails({
          email: response?.data?.data?.email,
          country: response?.data?.data?.countryOfResidenceCode,
          //response?.data.data?.countryOfResidenceCode??
          mobileNo: response?.data?.data?.phoneNo_Res
            ? response?.data?.data?.phoneNo_UAE
            : "",
          pmsConfirmationNo: response?.data?.data?.pmsConfirmationNo,
          reservationNameID: response?.data?.data?.reservationNameID,
          firstName: response?.data?.data?.firstName,
          lastName: response?.data?.data?.lastName,
        });
        if (proceedCount == 0) {
          dispatch(
            setEmailDetails({
              mail: response?.data?.data?.email,
              phone: response?.data?.data?.phoneNo_Res,
            })
          );
        }
        setTotalCount(response?.data?.data?.adultCount);
     
        if (response.data?.data?.adultCount > 0) {
          if (amount > 0) {
            localStorage.setItem("DueAmount", amount);
            setTotalDue(amount);
          } else {
            localStorage.setItem("DueAmount", 0);
            setTotalDue(0);
          }
        }
        setLoading(false);
      } else {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Unable to retrieve reservation. Please check your details or proceed to the front desk for assistance."
          ),
        }));
        setLoading(false);
        return;
      }
      if (response.data.statusCode === 102) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.data.message,
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
    }
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  const closeErrorModal4 = () => {
    setErrorModalData4((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  const stepEmailPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.CHECKIN_THANKYOU_PAGE
  );
  const configs = stepEmailPage?.configs;
  const emailRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.EMAIL_REQUIRED
  );
  const smsRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.SMS_REQUIRED
  );
  const whatsappRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.WHATSAPP_REQUIRED
  );

  const confirmationDetailsHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (name === "mobileNo") {
      setMobileNoValidation(false);
    }
    if (name === "email" && value) {
      // Auto-fill only if not already auto-filled and specific patterns are matched
      const isBackspacing = e.nativeEvent.inputType === "deleteContentBackward";
      let newValue = value;
      if (!isBackspacing) {
        if (value.endsWith("@y") && !value.includes("@yahoo.com")) {
          newValue = value.replace("@y", "@yahoo.com");
        } else if (value.endsWith("@g") && !value.includes("@gmail.com")) {
          newValue = value.replace("@g", "@gmail.com");
        }
      }
      value = newValue;
      setEnterEmailValidation(false);
    }
    if (name === "email" && mailFormat.test(value)) {
      setEmailValidation(false);
    }
    if (name === "country") {
      setCountryValidation(false);
    }
    setConfirmationDetails((state) => ({ ...state, [name]: value }));
  };

  const gotSignature = () => {
    setUrl(signature.getTrimmedCanvas().toDataURL("image/png"));
  };
  const addConfirmationNo = (newItem) => {
    setConfirmationNos((prevItems) => [...prevItems, newItem]);
  };
  const handleCheckboxChange = (e) => {
    setUserAgreed(e.target.checked);
  };

  const navigateToPayment = () => {
    if (totalDue > 0) navigate(`${CHECKIN_PAYMENT}`, { replace: true });
    else navigate(`${KEY}`, { replace: true });
  };

  const clearSign = () => {
    signature.clear();
    setUrl(null);
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConfirmationDetails((preState) => ({ ...preState, [name]: value }));
    setTimeChanged(true);
  };

  useEffect(() => {
    if (!stage) {
      setLoading(true);
      // checkStep();
      setLoading(false);
    }
    setConfirmationDetails((preState) => ({
      ...preState,
      hrs: "11",
      mins: "00",
      country: reservationData?.countryOfResidence || "",
      // pmsProfileID: "",
      // pmsConfirmationNo: "",
    }));
  }, [reservationData]);

  //api call

  const updateProfile = async (isValidate, profilesToSend) => {
    try {
      setEnterEmailValidation(false);
      setCountryValidation(false);
      setMobileNoValidation(false);
     
      if (isValidate === true) {
        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!confirmationDetails.email) {
          setEnterEmailValidation(true);
          setErrorModalData4((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t("Email validation failed"),
          }));
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        if (!mailFormat.test(confirmationDetails.email) && emailRequired) {
          setEmailValidation(true);
          setErrorModalData4((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t("Email validation failed"),
            
          }));
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        // if (!confirmationDetails.country) {
        //   setCountryValidation(true);
        // }
        if (
          !confirmationDetails.mobileNo
          // &&
          // (smsRequired || whatsappRequired)
        ) {
          setMobileNoValidation(true);
          setErrorModalData4((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t("Mobile Number validation failed"),
          }));
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
        if(url===null||url===undefined)
          {
            setErrorModalData4((state) => ({
              ...state,
              toggleError: true,
              errorMessage: t("Signature required"),
            }));
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            return false;
          }
        if (
          !confirmationDetails.email ||
          // !confirmationDetails.country ||
          !confirmationDetails.mobileNo ||
          !mailFormat.test(confirmationDetails.email)
        ) {
          return;
        }
      
      }

      setLoading2(true);
      if (proceedCount == 0) {
        dispatch(
          setEmailDetails({
            mail: confirmationDetails.email,
            phone: confirmationDetails.mobileNo,
          })
        );
      }
      const response = await updateUserProfile(profilesToSend, token);
      let confNo = profilesToSend[0].pmsConfirmationNo;
      let reservationNameID = profilesToSend[0].reservationNameID;

      if (response.data.result === false) {
        setErrorModalData2((state) => ({
          ...state,
          toggleError: true,
          errorMessage:
            response.data.message !== ""
              ? response.data.message
              : "Some error occured",
        }));
        setLoading2(false);
        return;
      }
      if (response.data.statusCode === 102) {
        setErrorModalData2((state) => ({
          ...state,
          toggleError: true,
          errorMessage: response.data.message,
        }));
        setLoading2(false);
        return;
      } else {
        if (
          response.data.data !== null &&
          response.data.data !== undefined &&
          response.data.data[0].pmsConfirmationNo !== null &&
          response.data.data[0].pmsConfirmationNo !== undefined
        ) {
          confNo = response.data.data[0].pmsConfirmationNo;
          reservationNameID = response.data.data[0].reservationNameID;
        }
      }
      //confirmNo

      const RegCard = {
        reservationID: 0,
        confirmationNo: confNo,
        signatureBase64: url,
        propertyCode: propertyData?.data?.propertyCode,
        documentType: "RegCard",
        tnChecked: userAgreed,
      };
      const response2 = await saveRegistrationCard(RegCard, token);
      if (response2.data.result === true && response2.status === 200) {
        const totalCount = parseInt(localStorage.getItem("totalCount") || 0);
        if (totalCount > 1 && propertyData?.data?.pms === "OHIP-SHARER") {
          if (confirmNo !== confNo && confNo !== undefined && confNo !== null) {
            addConfirmationNo({
              ConfirmationNo: confNo,
              ReservationNameID: reservationNameID,
            });
          }

          if (totalCount - 1 > proceedCount) {
            setProceedCount((prevCount) => prevCount + 1);
            setShowMultiConfirmModal(true);
            setLoading2(false);
            return;
          }
        }

        setDoCheckin(true);
      }

      // console.log("totalAmount-==##" + totalDue);
      // if (totalDue > 0) {
      //   navigate(`${CHECKIN_PAYMENT}`, { replace: true });
      // }
      // else {

      //   console.log("profilesToSend[0].pmsConfirmationNo ==> tt " + JSON.stringify(confirmationNos));
      //   const checkinData = {
      //     confirmationNo: confirmNo,
      //     reservationNameID: userData?.reservationNameID,
      //     payment: null,
      //     confirmationNumbers: JSON.stringify(confirmationNos)
      //   };
      //
      //   const response2 = await checkIn(checkinData, token);
      //   if (response2?.data?.result === true) {
      //     setLoading2(false);
      //     setSuccessLoader(true);
      //     setTimeout(() => {
      //       setSuccessLoader(false);
      //       navigate(KEY, { replace: true });
      //     }, 2000);
      //     return;

      //   } else if (
      //     response2?.data?.message !== null &&
      //     response2?.data?.message !== ""
      //   ) {
      //     setLoading2(false);
      //     setErrorModalData((state) => ({
      //       ...state,
      //       toggleError: true,
      //       errorMessage: response2?.data?.message ?? "Checkin failed",
      //     }));
      //   } else {
      //     setSuccessLoader(false);
      //     setLoading2(false);
      //     setErrorModalData((state) => ({
      //       ...state,
      //       toggleError: true,
      //       errorMessage: "Checkin failed",
      //     }));
      //     setTimeout(() => {
      //       navigate(`${HOME}`, { replace: true });
      //     }, 2000);
      //   }
      // }
      //navigate("/passport-scan-payment");
      //let amountDue=   localStorage.getItem("DueAmount");

      return;
    } catch (error) {
      setLoading2(false);
      setErrorModalData2((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message ?? t("Some error occured"),
      }));
      return;
    }
  };
  const transactionGUIDSet = new Set();

  useEffect(async () => {
    console.log("isDoCheckin==##" + isDoCheckin);
    if (isDoCheckin === true) {
      if (totalDue > 0) {
        setLoading(false);
        setLoading2(false);
        setLoading3(false);
        dispatch(setMultiConfirmationNos(confirmationNos));
        navigate(`${CHECKIN_PAYMENT}`, { replace: true });
      } else {
        await callCheckin();
      }
    }
  }, [isDoCheckin]);

  const callCheckin = async () => {
    setLoading(true);
    const preCheckin = getParametersFromLocalStorage(SESSION_ENUM.PRECHECKIN);
    if (preCheckin) {
      await moveToQueueRoom();
    } else {
      const checkinData = {
        confirmationNo: confirmNo,
        reservationNameID: userData?.reservationNameID,
        payment: null,
        confirmationNumbers: JSON.stringify(confirmationNos),
      };

      const response2 = await checkIn(checkinData, token);
      if (response2?.data?.result === true) {
        setDoCheckin(false);
        setLoading2(false);
        setLoading(false);
        setSuccessMessage(t("Checked in successfully!!"));
        setSuccessLoader(true);
        setTimeout(() => {
          setSuccessLoader(false);
          navigate(KEY, { replace: true });
        }, 2000);
        return;
      }
      //else if (
      //   response2?.data?.message !== null &&
      //   response2?.data?.message !== ""
      //  )
      //{
      //   debugger;
      //   setDoCheckin(false);
      //   setSuccessLoader(false);
      //   setLoading(false);
      //   setErrorModalData((state) => ({
      //     ...state,
      //     toggleError: true,
      //     errorMessage: t("Checkin failed"),
      //   }));
      //   setTimeout(() => {
      //     handlebackPage();
      //   }, 2000);
      // }
      else {
        setLoading(false);
        setLoading2(false);
        setLoading3(false);
        setDoCheckin(false);
        setErrorModalData4((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t("Checkin failed"),
        }));
        setTimeout(() => {
          handlebackPage();
        }, 2000);
        return;
      }
    }
  };

  const moveToQueueRoom = async () => {
    const confirmationNo = localStorage.getItem("confirmationNo");
    const reservationNameID = localStorage.getItem("reservationNameID");
    const requestData = {
      confirmationNo: confirmationNo,
      reservationNameID: reservationNameID,
    };
    const resp = await queueRoom(requestData, token);
    if (resp?.data?.result === true) {
      setLoading(false);
      setSuccessMessage(t("Pre-Checked in successfully!!"));
      setSuccessLoader(true);
      setTimeout(() => {
        setSuccessLoader(false);
        navigate(CHECKIN_THANKYOU, { replace: true });
      }, 2000);
      return;
    } else {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "PreCheck-in Failed. Please visit front desk for assistance"
        ),
      }));
    }
  };
  const updateProfileCall = () => {
    let profiles = [];
    if (profileData !== null && profileData?.length > 0) {
      profileData.forEach((el) => {
        let result = {
          transactionGUID: el.transactionGUID,
          pmsProfileID:
            el.pmsProfileID === null || el.pmsProfileID === undefined
              ? ""
              : el.pmsProfileID,
          guestDetailID:
            el.guestDetailID === null || el.guestDetailID === undefined
              ? ""
              : el.guestDetailID,
          isPrimary:
            el.isPrimary === null || el.isPrimary === undefined
              ? false
              : el.isPrimary,
          documentBase64Image: el?.DocumentImageSide1,
          faceImageBase64Image: el?.Portrait,
          AdditionalDocumentBase64Image: el?.DocumentImageSide2,
          pmsConfirmationNo: el?.pmsConfirmationNo,
          phoneNo_UAE: confirmationDetails.mobileNo,
          email: confirmationDetails.email,
          isFaceMatched: el?.isFaceMatch ?? false,
          documentNumber: el?.DocumentNumber ?? "",
          documentType: el?.DocumentType,
          nationalityCode: el?.NationalityCode,
          issueDate: el?.IssueDate,
          expiryDate: el?.ExpiryDate,
          gender: el.Gender,
          title: "",
          firstName: el?.FirstName, //el?.GivenName ?? el?.FirstName??,
          middleName: "",
          lastName: el?.LastName,
          arabicName: "",
          fullName: el?.FullName || el?.GivenName || "",
          birthPlace: "",
          dateofBirthDT: el?.DateOfBirth,
          // issueCountryCode: el.IssueCountryCode,
          countryOfResidenceCode: confirmationDetails.country,
          emiratesName: "",
          createdDate: "",
          city: "",
          state: "",
          pincode: "",
          placeOfIssue: "",
          addressLine1: "",
          addressLine2: "",
          liveFaceImage: el?.liveFaceImage,
          manualFaceAuthenticatedBy: el?.manualAuthby ?? "",
          isFaceManuallyVerified: el?.isManualAuth ?? false,
          primaryPmsConfirmationNo: confirmNo,
          primaryReservationNameID: reservationNameID,
          reservationNameID: el?.reservationNameID,
          taxNo1: el?.TaxNumber,
        };
        profiles.push(result);
        transactionGUIDSet.add(el.transactionGUID);
      });
    } else {
      let result = {
        transactionGUID: "",
        profileDocumentID: 0,
        profileID: 0,
        pmsProfileID: totalCount === 1 ? reservationData.pmsProfileID : 0,
        guestDetailID:
          totalCount === 1
            ? reservationData.guestDetailID === null ||
              reservationData.guestDetailID === undefined
              ? ""
              : reservationData.guestDetailID
            : "",
        isPrimary:
          totalCount === 1
            ? reservationData.isPrimary === null ||
              reservationData.isPrimary === undefined
              ? false
              : reservationData.isPrimary
            : false,
        documentBase64Image: "",
        faceImageBase64Image: confirmationDetails?.faceImageBase64Image,
        AdditionalDocumentBase64Image: "",
        pmsConfirmationNo: reservationData.pmsConfirmationNo,
        isPassportAdded: true,
        isFrontIDAdded: true,
        isBackIDAdded: true,
        phoneNo_UAE: confirmationDetails.mobileNo,
        email: confirmationDetails.email,
        isDocVerified: true,
        isFaceMatched: true,
        documentNumber: "",
        documentType: "",
        nationalityCode: "",
        issueDate: "",
        expiryDate: "",
        gender: "",
        title: "",
        firstName: confirmationDetails.firstName,
        middleName: "",
        lastName: confirmationDetails.lastName,
        arabicName: "",
        fullName: "",
        birthPlace: "",
        dateofBirthDT: "",
        issueCountryCode: "",
        countryOfResidenceCode: confirmationDetails.country,
        emiratesName: "",
        createdDate: "",
        city: "",
        state: "",
        pincode: "",
        placeOfIssue: "",
        addressLine1: "",
        addressLine2: "",
        liveFaceImage: "",
        manualFaceAuthenticatedBy: confirmationDetails?.manualAuthby ?? "",
        isFaceManuallyVerified: confirmationDetails?.isManualAuth,
        primaryPmsConfirmationNo: confirmNo,
        primaryReservationNameID: reservationNameID,
        reservationNameID: "",
        taxNo1: "",
      };
      profiles.push(result);
    }

    if (propertyData?.data?.pms === "OHIP-SHARER") {
      const distinctTransactionGUIDs = Array.from(transactionGUIDSet);
      const transactionGuid = distinctTransactionGUIDs[proceedCount];

      const profilesToSend = profiles.filter(
        (item) => item.transactionGUID == transactionGuid
      );

      setGuestCount(
        (prevCount) => prevCount + (profilesToSend?.length - 1) + 1
      ); // 2 + 1 = 3

      updateProfile(true, profilesToSend);
    } else {
      let profilesToSend = profiles.filter((o) =>
        Object.values(o).some((v) => v !== null)
      );
      updateProfile(true, profilesToSend);
    }
  };
  const handlebackPage = () => {
    try {
      console.error("going home page");
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div>
      {reservationData ? (
        <UserReservationDetails
          loading={loading}
          loading2={loading2}
          loading3={loading3}
          openModal={openModal}
          reservationData={reservationData}
          confirmationDetails={confirmationDetails}
          erroModalData={erroModalData}
          erroModalData2={erroModalData2}
          closeErrorModal={closeErrorModal}
          closeErrorModal2={closeErrorModal2}
          confirmationDetailsHandler={confirmationDetailsHandler}
          setSignature={setSignature}
          setUrl={setUrl}
          signature={signature}
          gotSignature={gotSignature}
          handleCheckboxChange={handleCheckboxChange}
          clearSign={clearSign}
          navigateToPayment={navigateToPayment}
          userAgreed={userAgreed}
          url={url}
          updateProfile={updateProfileCall}
          erroModalData3={erroModalData3}
          closeErrorModal3={closeErrorModal3}
          openCheckOutTimeModal={openCheckOutTimeModal}
          toggleCheckoutTimeModal={toggleCheckoutTimeModal}
          setTimeChanged={setTimeChanged}
          timeChanged={timeChanged}
          onChangeHandler={onChangeHandler}
          emailValidation={emailValidation}
          countryValidation={countryValidation}
          mobileNoValidation={mobileNoValidation}
          enterEmailValidation={enterEmailValidation}
          property={propertyData?.data}
          userData={userData}
          countries={countries}
          handlebackPage={handlebackPage}
        />
      ) : (
        <>
          <LoaderPopup loading={true} />
        </>
      )}

      <Footer
        page={"signature"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
      {toggleCheckoutTimeModal && (
        <CheckoutTimeModal
          closeCheckoutTimeModal={closeCheckoutTimeModal}
          togglePackageApi={togglePackageApi}
          setTogglePackageApi={setTogglePackageApi}
        />
      )}
      <SuccessPopup
        loading={successLoader}
        successMessage={t(successMessage)}
      />
      <ErrorPopup
        closeErrorModal={closeErrorModal4}
        erroModalData={erroModalData4}
      />
      <MultipleRegCard
        showConfirmModal={showMultiConfirmModal}
        closeModal={closeModal}
        confirmSharerReg={confirmSharerReg}
      />
    </div>
  );
};

export default ReservationDetailsPassportScan;
