import { STEP_MASTER_ENUM, timerConstantValue } from "../../constants/constant";
import { getParametersFromLocalStorage, getStepMaster } from "../../helpers/commonHelper";
import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";

import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import {
  RESERVATION_DETAIL,
} from "../../routes/Paths";
import Upgrade from "../../components/Upgrade/Upgrade";
import { getAllUpsellPackages } from "../../network/apiService";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import {
  setModuleType,
} from "../../store/Checkin/reservationformSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UpgradeOptionPassportScan = () => {
  const { t } = useTranslation();
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [upgradeRoomModal, setUpgradeRoomModal] = useState(false);
  const [toggleSkipModal, setToggleSkipModal] = useState(false);
  const [toggleDoneModal, setToggleDoneModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const { type } = useParams();
  const [upgradeOptions, setUpgradeOptions] = useState([]);
  const [restartTimer, setRestartTimer] = useState(false);
  const propertyData = useSelector(getPropertyInfo);
  const [loading, setLoading] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [upgrade, setUpgrade] = useState({
    breakFast: false,
    earlyCheckout: false,
    airportDropOff: false,
  });
  const navigate = useNavigate();
  const stepMaster = useSelector(state => getModuleConfiguration(state));
  const upsellPage = getStepMaster(stepMaster,STEP_MASTER_ENUM.UPSELL_PAGE);
  const token = getParametersFromLocalStorage("token");
  const upgradePage =
    (stepMaster && stepMaster.length
      ? stepMaster.find((ele) => ele.stepCode === STEP_MASTER_ENUM.UPSELL_PAGE)
        ?.isEnabled
        ? true
        : false
      : false) ?? false;

  
  const [showUpsellPage, setShowUpsellPage] = useState(upsellPage);
  const [show, setShow] = useState(false);

  const [roomOptions, setRoomOptions] = useState({
    room: null,
    price: null,
  });
  const selectedLanguage = useSelector(getSelectedLanguage);
  
  useEffect(async () => {
    if (type !== undefined) setModuleType(type);

    if (!showUpsellPage) {
      navigate(`${RESERVATION_DETAIL}`, { replace : true});
    } 
  }, []);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  useEffect(() => {
    // Set a 5 seconds delay
    const timer = setTimeout(() => {
      // Set the button visibility to true
      setShow(true);
    }, 5000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []); // Pass an empty array to run the effect only once

 
  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openUpgradeModal = () => {
    setUpgradeRoomModal(true);
  };

  const closeUpgradeModal = () => {
    setUpgradeRoomModal(false);
  };

  const skipModal = () => {
    setToggleSkipModal(true);
  };

  const closeSkipModal = () => {
    setToggleSkipModal(false);
  };

  const closeDoneModal = () => {
    setToggleDoneModal(false);
  };

  const resetStates = () => {
    setUpgrade({
      breakFast: false,
      earlyCheckout: false,
      airportDropOff: false,
    });
    setRoomOptions({
      room: null,
      price: null,
    });
    setToggleDoneModal(true);
  };

  const skipHandler = () => {
    setUpgrade({
      breakFast: false,
      earlyCheckout: false,
      airportDropOff: false,
    });
    setRoomOptions({
      room: null,
      price: null,
    });
  };

  const removeRoomOptions = () => {
    setRoomOptions({
      room: null,
      price: null,
    });
    setUpgradeRoomModal(false);
  };
  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
    if (upgradePage === false) {
      navigate(RESERVATION_DETAIL, { replace: true });
      return;
    } else if (upgradePage === true) {
      fetchUpgradeOptions(token);
      return false;
    }
  }, []);
  const wait = (milliseconds) =>
    new Promise((res, rej) =>
      setTimeout(
        () =>
          rej(
            new Error(`Request timed out after ${milliseconds / 1000} seconds`)
          ),
        milliseconds
      )
    );
    const closeErrorModal = () => {
      setErrorModalData((state) => ({
        ...state,
        toggleError: false,
        errorMessage: "",
      }));
    };
  const fetchUpgradeOptions = async (token) => {
    try {
      setLoading(true);
      const confirmNo = localStorage.getItem("confirmationNo");
      const ReservationNameID = localStorage.getItem("reservationNameID");
      
      const response = await Promise.race([
        getAllUpsellPackages(token,confirmNo, ReservationNameID),
        wait(30000),
      ]);
      if(response?.status === 200 && response?.data?.data !== undefined && response?.data?.data !== null && response?.data?.data?.length > 0){
        const options = response?.data?.data?.map((option) => ({
          ...option,
          selected: false,
        }));
        setUpgradeOptions(options);
        setLoading(false);
      }
      else{
        navigate(`${RESERVATION_DETAIL}`, { replace: true });
      }
      
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          t("Unable to get packages. Please contact front desk for assistance.")
        ),
      }));
      setLoading(false);
    }
  };

  
  const upgradeHandler = (option, status) => {
    if (option === "breakfast" && status === "selected") {
      setUpgrade((state) => {
        return { ...state, breakFast: true };
      });
    }

    if (option === "breakfast" && status === "remove") {
      setUpgrade((state) => {
        return { ...state, breakFast: false };
      });
    }

    if (option === "earlycheckout" && status === "selected") {
      setUpgrade((state) => {
        return { ...state, earlyCheckout: true };
      });
    }

    if (option === "earlycheckout" && status === "remove") {
      setUpgrade((state) => {
        return { ...state, earlyCheckout: false };
      });
    }

    if (option === "airportdropoff" && status === "selected") {
      setUpgrade((state) => {
        return { ...state, airportDropOff: true };
      });
    }

    if (option === "airportdropoff" && status === "remove") {
      setUpgrade((state) => {
        return { ...state, airportDropOff: false };
      });
    }
  };
  return (
    <div>
       <LoaderPopup loading={loading} />
       <ErrorPopup
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
      />
      <Upgrade
        showModal={showModal}
        upgradeRoomModal={upgradeRoomModal}
        toggleSkipModal={toggleSkipModal}
        toggleDoneModal={toggleDoneModal}
        timerValue={timerValue}
        restartTimer={restartTimer}
        upgrade={upgrade}
        roomOptions={roomOptions}
        skipModal={skipModal}
        openModal={openModal}
        resetStates={resetStates}
        removeRoomOptions={removeRoomOptions}
        openUpgradeModal={openUpgradeModal}
        closeUpgradeModal={closeUpgradeModal}
        closeSkipModal={closeSkipModal}
        closeDoneModal={closeDoneModal}
        upgradeHandler={upgradeHandler}
        skipHandler={skipHandler}
        setRoomOptions={setRoomOptions}
        setUpgrade={setUpgrade}
        navigateUrl = {`${RESERVATION_DETAIL}`}
        upgradeOptions = {upgradeOptions}
      />
      <Footer
        page={"upgrade"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard showModal={showModal} resetTimerAndClosePopUp={resetTimerAndClosePopUp} />
      )}
    </div>
  );
};

export default UpgradeOptionPassportScan;
